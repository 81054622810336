.App {
    display: flex;
    flex-direction: column;
}

.Cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}