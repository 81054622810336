@import url(https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap);
.App {
    display: flex;
    flex-direction: column;
}



.imgs {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.imgs .imagens{
    margin-left: 8px;
    margin-bottom: 8px;
    margin-right: 8px;
    margin-top: 8px;
}
.button{
    
   
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-bottom: 8px;
    
    
}
.App {
    display: flex;
    flex-direction: column;
}

.Cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.Card {
    flex: 1 1;
    margin: 20px;
    border: 3px solid #000;
    border-radius: 10px;
    overflow: hidden;
    background-color: #fff;

    display: flex;
    flex-direction: column;
    min-width: 150px;
    max-width: 280px;
}

.Card .Content {
    flex: 1 1;
    padding: 15px 0px;
    color: #000;
    align-content: center;
    align-items: center;
    text-align: center;

}

.Card .imagem{
    
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-bottom: 8px;
    
    
}
.Card .button{
    
   
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-bottom: 8px;
    
    
}

.Card .Footer {
    background-color: #000;
    color: #fff;
    padding: 5px 0px;
    align-content: center;
    text-align: center;
}
:root {
    --color-primary: #338000;
}

html, body {
    height: 100%;
    font-family: "Ubuntu", sans-serif;
}

#root {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.content {
    flex: 1 0 auto;
}

.footer {
    flex-shrink: 0;
    text-align: center;
}

.bg-primary {
    background-color: #338000 !important;
    background-color: var(--color-primary) !important;
}

.text-primary {
    color: #338000 !important;
    color: var(--color-primary) !important;
}

