.Card {
    flex: 1;
    margin: 20px;
    border: 3px solid #000;
    border-radius: 10px;
    overflow: hidden;
    background-color: #fff;

    display: flex;
    flex-direction: column;
    min-width: 150px;
    max-width: 280px;
}

.Card .Content {
    flex: 1;
    padding: 15px 0px;
    color: #000;
    align-content: center;
    align-items: center;
    text-align: center;

}

.Card .imagem{
    
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-bottom: 8px;
    
    
}
.Card .button{
    
   
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-bottom: 8px;
    
    
}

.Card .Footer {
    background-color: #000;
    color: #fff;
    padding: 5px 0px;
    align-content: center;
    text-align: center;
}