.App {
    display: flex;
    flex-direction: column;
}



.imgs {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.imgs .imagens{
    margin-left: 8px;
    margin-bottom: 8px;
    margin-right: 8px;
    margin-top: 8px;
}
.button{
    
   
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-bottom: 8px;
    
    
}